.main[data-v-4b3f3a7a] {
  width: 98%;
  margin: 0 auto;
  padding-top: 1%;
}
.top[data-v-4b3f3a7a] {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
  padding: 10px 10px 30px;
  border-radius: 4px;
}
.top p[data-v-4b3f3a7a] {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
}
.top .each_info[data-v-4b3f3a7a] {
  padding-left: 20px;
  margin-top: 15px;
}
.top .each_info .info_title[data-v-4b3f3a7a] {
  padding-bottom: 5px;
  font-size: 13px;
}
.top .each_info .info_count[data-v-4b3f3a7a] {
  text-indent: 3em;
  font-size: 13px;
}
.top .each_info .info_count span[data-v-4b3f3a7a] {
  font-size: 24px;
}
.pie[data-v-4b3f3a7a] {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
  border-radius: 4px;
  height: 170px;
  padding: 10px 20px;
}
.pie p[data-v-4b3f3a7a] {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
}
.pie .pie_info[data-v-4b3f3a7a] {
  text-align: center;
}
.line[data-v-4b3f3a7a] {
  margin-top: 10px;
  background-color: var(--themeColorLeftMenu, #ecf7f9);
  border-radius: 4px;
  padding: 10px 20px;
}
.line .line_select[data-v-4b3f3a7a] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total_list[data-v-4b3f3a7a] {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
  border-radius: 4px;
  padding: 10px 20px;
}
.content[data-v-4b3f3a7a] {
  border: 1px solid var(--themeColor, #17a2b8);
  border-right: none;
  border-radius: 3px;
}
.content tr[data-v-4b3f3a7a] {
  padding: 0;
  margin: 0;
}
.content td[data-v-4b3f3a7a] {
  border-right: 1px solid var(--themeColor, #17a2b8);
  line-height: 26px;
  padding: 0 6px;
  color: #333;
  font-size: 14px;
}
.content .center[data-v-4b3f3a7a] {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.nonborder_content tr[data-v-4b3f3a7a] {
  padding: 0;
  margin: 0;
}
.nonborder_content td[data-v-4b3f3a7a] {
  border-bottom: 1px solid var(--themeColor, #17a2b8);
  line-height: 30px;
  padding: 5px 6px;
  color: #333;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nonborder_content .center[data-v-4b3f3a7a] {
  text-align: center;
  font-size: 13px;
  color: #333;
}